import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import "./src/styles/bootstrap.min.css"
import "./src/styles/style.css"
import "./src/styles/form.css"

export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_KEY}>
            {element}
        </GoogleReCaptchaProvider>
    )
}

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-es-js": () => import("./../../../src/pages/faq.es.js" /* webpackChunkName: "component---src-pages-faq-es-js" */),
  "component---src-pages-funcionalidades-es-js": () => import("./../../../src/pages/funcionalidades.es.js" /* webpackChunkName: "component---src-pages-funcionalidades-es-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-legalidad-es-js": () => import("./../../../src/pages/legalidad.es.js" /* webpackChunkName: "component---src-pages-legalidad-es-js" */),
  "component---src-pages-paquetes-es-js": () => import("./../../../src/pages/paquetes.es.js" /* webpackChunkName: "component---src-pages-paquetes-es-js" */)
}

